<template>
   <div v-if="item && item.id" class="shopDetail mobile">
        <div class="h5_header">
            <div @click="back()" class="h5_header_left">
                <i class="iconfont icon-fanhuitubiao"></i>
            </div>
            <div v-if="item.name" style="font-size:18px;" class="h5_header_center ellipsis">{{item.name}}</div>
        </div>
        <div class="mobile-scroll">
            <div style="width:100%;height:300px;">
                <div v-if="item.photo_render && item.photo_render.length>0" class="bg_img" :style="{backgroundImage: 'url(' + imgUrl+item.photo_render[0]+'!width_250px' + ')'}" alt=""></div>
            </div>
            <div class="content">
                <div v-if="item.name" class="item-name">
                    <h3>{{item.name}}</h3>
                </div>
                <div class="item-type font14-grey1">
                    <div v-if="item.set">商品分类：<span>{{item.set.name}}</span></div>
                    <div v-if="item.style">风格：<span>{{item.style.name}}</span></div>
                </div>
                <div class="font14-grey1">浏览量：{{item.n_visited || 0}}</div>
            </div>
            <div style="width:100%;">
                <div v-if="item.photo_size && item.photo_size.length>0" class="itemDetail">
                    <div v-for="(item,index) in item.photo_size" :key="index">
                        <img v-if="item" :src="imgUrl+item+'!width_250px'" alt="">
                    </div>
                </div>
                <div v-if="item.photo_story && item.photo_story.length>0" class="itemDetail">
                    <div v-for="(item,index) in item.photo_story" :key="index">
                        <img v-if="item" :src="imgUrl+item+'!width_250px'" alt="">
                    </div>
                </div>
            </div>
        </div>
   </div>
</template>
<script>
    import { IMG_URL_PRE } from '@/config';
    import { shopDetailId } from '@/service/mobile';
    import { getCompBaseInfoById } from '@/service/company';
    export default {
        components: {},
        data() {
            return {
                // imgUrl:require('../../assets/images/bg1.jpg'),
                imgUrl:IMG_URL_PRE,
                item:{},
            }
        },
        async created() {
            this.refreshItems();
            this.comp = await getCompBaseInfoById(this.$route.params.compid);
            document.title = this.comp.name;
        },
        methods: {
            back() {
                this.$router.go(-1);
            },
            goto(path){
                this.$router.push(path);
            },
            refreshItems() {
                console.log('refreshItems');
                shopDetailId({product_no:this.$route.params.id,share_user_id:this.$route.params.userid,}).then(rst => {
                    this.item = rst;
                    console.log(this.item);
                }).catch(err => {
                    console.log(err);
                    this.$message.error(err.message);
                });
            },
        }
    }
</script>
<style scoped src="./style.css"></style>
<style scoped>
    .shopDetail {
        width: 100%;
        height: 100%;
        /* font-size: 0; */
    }
    .content {
        padding:10px;
    }
    .item-type {
        width: 100%;
    }
    .item-type>div {
        display:inline-block;
        vertical-align: top;
        margin-bottom: 10px;
        margin-right: 30px;
    }
    .item-type>div>span {
        color: #4D4D4D;
        font-weight: bold;
    }
    .item-name>h3 {
        font-size:18px;
        font-weight:bold;
        color:rgba(41,41,41,1);
        line-height:25px;
    }
    .itemDetail {
        width:100%;
        padding: 10px 0;
    }
    .itemDetail>div,.itemDetail>div>img {
        width:100%;
    }
</style>